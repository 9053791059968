// Required
@import 'styles/variables';
@import '~@britannica/ui/src/scss/britannica.scss';

// Overrides (required)
@import 'styles/overrides';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select.form-control {
  -webkit-appearance: auto;
  appearance: auto;
}

.border-width-2 {
  border-width: 2px;
}

.filter-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.spinner-grow {
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
