@import '../../../styles/variables';

.membership_col {
  .bottom-container {
    height: 40px;
  }

  label {
    .plan_description_container {
      min-height: 250px;
    }
  }

  // label:hover {
  //   .plan_description_container {
  //     border-color: #b8b8b8;
  //   }
  //   .tab-container{
  //     border-color: #b8b8b8;
  //   }
  //   .tab-not-active{
  //     border-top: 2px solid #b8b8b8 !important;
  //   }
  // }

  input[type='radio']:checked + label {
    .plan_description_container {
      border-color: $blue;
    }
    .tab-active {
      border-color: $blue;
    }

    .tab-not-active {
      border-top: 2px solid $blue !important;
    }
  }
}
