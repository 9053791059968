.global-nav-title {
  background-image: url('../../assets/images/thistle-white.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 33px;
  font-family: 'Cinzel', serif;
}

.line-2 {
  letter-spacing: 2.5px;
}
